<template>
  <div class="st-pusher">
    <div class="st-content">
      <div class="st-content-inner">
        <div class="main clearfix" ref="main">
          <TopHeader :kindTheme="kindTheme" v-if="!isMobileApp && !isIframe && !isSabre" />
          <NavigationHeader v-if="!isMarketerSite && !isMobileApp && !is404Page && !noBanner && isHomePage && !isSabre" />
          <TopNav :kindTheme="kindTheme" v-if="!isMobileApp && !is404Page && !noBanner && !isFooterPage && !isSabre" />
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import mixinTheme from '@/components/mixins/mixinTheme';

const { VUE_APP_CHANNEL_MOBILE_APP } = process.env;

export default {
  name: 'ContentWrapper',
  components: {
    TopHeader: () => import('@/components/HeaderFooter/TopHeader/TopHeader'),
    TopNav: () => import('@/components/HeaderFooter/TopNav/TopNav'),
    NavigationHeader: () => import('@/components/HeaderFooter/NavigationHeader/NavigationHeaderTheme0'),
  },
  mixins: [mixinTheme],
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters({
      typeChannel: 'GET_TYPE_CHANNEL',
      currentPage: 'GET_CURRENT_PAGE',
      isMarketerSite: 'GET_IS_MARKETER_SITE',
    }),
    isMobileApp() {
      return this.typeChannel?.toLowerCase() === VUE_APP_CHANNEL_MOBILE_APP.toLowerCase();
    },
    is404Page() {
      return this.typeChannel?.toLowerCase() === '404-page';
    },
    isFooterPage() {
      return this.currentPage === 'footer';
    },
    isHomePage() {
      return this.currentPage === 'home';
    },
    isSabre() {
      return this.currentPage.includes('sabre');
    },
    noBanner() {
      return Boolean(this.$route.query.afterBook);
    },
    isDomainVercel() {
      return window.location.host.includes('vercel') || window.location.host.includes('localhost');
    },
    isIframe() {
      return window.self !== window.top;
    },
  },
};
</script>
